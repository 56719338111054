@import "../../../mixins.scss";

.ademe {
  .main .header {
    position: fixed;
    top: 0;
    display: flex;
    right: 0;
    //width: calc(100% - 120px);
    width: calc(100% - 87px);
    justify-content: flex-end;
    background: transparent;
    transition: all 0.10s ease;
    padding-right: 1.7rem;
    //z-index: 100;
    z-index: 1000000;
    height: 87px;

    &.isPage {
      border-bottom: solid 1px #E9E9E9;
      &.menuShow {
        border-bottom: solid 1px transparent;
      }
    }

    .fx-backgrd {
      position: absolute;
      opacity: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      z-index: -1;

      &:after {
        //@include wait(#ececec)
        @include wait(#828282);
      }

    }

    .scrollbar-container {
      height: 200px;
    }

    .menu-numero {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      //margin-top: 0.3rem;
      overflow: visible !important;
      //border: 1px solid #ffffff;
      border-radius: 3px;

      button {
        border: 1px solid #ffffff !important;
        border-radius: 5px;
      }

      &:after {
        @include wait(#b8b8b8);
      }

      .numeros-menu-outer {
        width: calc(100% + 0px);
        left: 0;
        border-top: solid 1px transparent;
        box-shadow: 0 4px 15px 0 rgba(15,15,89,0.10);

        .ps__thumb-y {
          background-color: #ff4a7a;
          margin-right: 5px;
        }

        /*.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
          margin-right: 5px;
        }*/

      }

      .show-more-btn {
        text-align: center;
        padding-top: 0.5rem;
        padding-bottom: 0.4rem;
        font-size: 0.6rem;
        font-weight: 600;
      }

      .numeros-menu {
        height: auto;
        overflow-y: auto;
        margin-bottom: 0px;

        &.show-more {
          height: 150px;
          overflow-y: scroll;
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background: #4E49FC;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #4E49FC;
        }

      }

      &.menuShow {
        color: black;

        button {
          background: #FF5F7D;
          border: 1px solid #f95e7d !important;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }

        //&.scrolled {
        //  border: 1px solid #f95e7d !important;
        //}

        .menu-numeros-btn {
          color: white;
          text-decoration: none;

          i {
            color: #0F0F59;
          }

        }

        .numeros-menu-outer {
          //border-top: solid 1px rgba(151, 151, 151, 0.12156862745098039);
        }

      }

    }

    .scroll-block {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6px;
      transform: translateY(100%);
      border-top: solid 1px #dbdbdb;

      //> div:first-child {
      //  flex-basis: 272px;
      //}

      .scroll-inner {
        min-width: 400px;
        width: 100%;
        margin: 0 auto;
        max-width: 900px;
        background: #dbdbdb;
        display: flex;
        //justify-content: center;

        .scroll-fx {
          width: 0px;
          height: 100%;
          background: #2e6066;
        }

      }

    }

    &.scrolled:not(.menuShow) {
      background: white;
      transition: all 0.15s ease;

      .menu-numero {
        button { border-color: black !important; }
      }

      .menu-numero.menuShow button {
        border-color: #f95e7d !important;
      }

        .menu-numeros-btn {
        color: black;
      }

      .scroll-block {
        display: flex;
      }

      .inner {
        h1 {
          a {
            color: black;
          }
        }
      }

    }

    .inner {
      display: flex;
      justify-items: center;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .logo {
        img {
          width: 130px;
        }

        &:after {
          @include wait(#b8b8b8);
        }

      }

      h1 {
        padding-left: 1.7rem;
        //font-size: 1.5rem;
        margin-bottom: 0;
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;

        a {
          color: white;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          > div {
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 2.3rem;
            text-transform: uppercase;
          }

          img {
            height: 1.5rem;
            margin-right: 0.4rem;
            width: auto;
          }

          &:after {
            @include wait(#ececec);
          }

        }
      }

    }

    .menu-toggle-btn {
      background: #4e49fc;
      color: white;
      border: 0;
      outline: none;
      width: 100px;
      height: 90px;
      margin-left: 3em;
      cursor: pointer;
    }

  }

  .menu-numeros-btn {
    font-size: 0.76rem;
    font-weight: 600;
    //padding: 0.9rem;
    //padding-top: 0.6rem;
    //padding-bottom: 0.6rem;
    //padding-right: 0.9rem;
    padding: 0.9rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.7rem;
    background: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;

    span {
      margin-right: 0.9rem;
      font-size: 0.9rem;
    }

    i {
      color: #FF5F7D;
      font-size: 1rem;
    }

  }

  .numeros-menu-outer {
    position: absolute;
    //background: #f1f1f1;
    background: white;
    padding: 0px;
    padding-bottom: 0.5rem !important;
    z-index: 1;
    bottom: 0rem;
    left: 0;
    transform: translateY(100%);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .inner-numero-menu {
      max-height: 150px;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }

  }

  .numeros-menu {

    .inner-numero-menu {
      max-height: 150px;
    }

    li {
      display: flex;
      margin-bottom: 0;
      align-items: center;
      border-bottom: solid 1px #E6E9EE;
      cursor: pointer;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background: rgba(253, 94, 124, 0.05);
      }

      &.current {
        background: rgba(253, 94, 124, 0.10);
        border-bottom: solid 1px transparent;

        a {
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          align-items: center;

          i {
            color: #FF5F7D;
            padding-right: 0.2rem;
            font-size: 1rem;
          }

        }

      }

    }

    a {
      color: #0F0F59;
      font-size: 0.75rem;
      line-height: 1.2rem;
      padding: 7%;
      padding-left: 10%;
      width: 100%;
    }

  }
}

@import "./header-media.scss";
