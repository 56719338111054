.ademe {
  .component {

    &.component-carrousel {
      width: 100% !important;
      margin-top: 2rem;
      margin-bottom: 2rem;


      .slick-slide {
        //border-radius: 5px;
      }

    }

    &.component-text {

      @media(max-width: 768px) {
        table {
          border-collapse: collapse;
          width: 100% !important;
          display: block;
          tbody {
            width: 100% !important;
            display: block;
          }
          tr {
            width: 100% !important;
            display: inline-grid;
            grid-template-columns: 50% 50%;

            td {
              padding: 1rem;
              width: 100%;
            }

          }
        }
      }

    }

    &.component-chiffres {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 100% !important;

      .slick-slider {
        height: 206px;

        @media (max-width: 600px) {
          height: 129px;
        }

        .slick-list, .slick-track{
          height: 100%;
        }

        .slick-slide {
          * {
            height: 100% !important;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

      }

      .slick-slide {
        border-radius: 5px;

        &:not(.slick-active) {
          .content-chiffre {
            height: 85% !important;
            transition: all 0.4s ease;

          }
        }

        &.slick-active {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          .content-chiffre {
            display: flex !important;
            background-color: #2E6066;
            color: white;
            transition: all 0.6s ease;
            //border-radius: 5px;

            .chiffre {
              opacity: 1;
              transition: opacity 1s ease;
            }

            .legende {
              opacity: 1;
              transition: opacity 1s ease;
            }

          }
        }

        .content-chiffre {
          background-color: #D8D8D8;
          transition: all 0.2s ease;
          display: flex;
          flex-direction:column;
          justify-content: center;
          align-items:center;
          padding-top: 3.5rem;
          padding-left: 1rem;
          padding-right: 1rem;
          padding-bottom: 3.5rem;

          .legende {
            opacity: 0;
            font-size: 1.2rem;
            color: white;
            width: 100%;
            text-align: center;
            font-size: calc(0.8rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
            font-size: calc(1.1rem + (26 - 14) * ((100% - 100px) / (1600 - 300)));
            font-weight: 800;
          }

          .chiffre {
            opacity: 0;
            font-size: 4rem;
            color: white;
            line-height: 3.5rem;
            font-weight: 400;
            font-size: calc(2.1rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
            font-size: calc(4rem + (26 - 14) * ((100% - 100px) / (1600 - 300)));
          }

          > * { height: auto !important;}

        }
      }
    }

  }

  @import "./components-media.scss";
}
