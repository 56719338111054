@media (max-width: 425px) {

  .ademe {
    .slick-track {
      display: flex
    }

    .slick-slide {
      width: 100vw !important;
      box-sizing: border-box;
    }

    .component-carrousel .slick-slider.center .slick-track {
      height: 186px;
    }

    .component.component-chiffres .slick-slide .content-chiffre .legende {
      padding-left: 2rem;
      padding-right: 2rem;
      text-align: center;
    }
  }

}
