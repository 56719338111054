body {

  .rgpd-outer {
    position: fixed;
    top: 0;
    opacity: 0;
    z-index: 100000000000000000;
    width: 100%;
    height: 100%;
    background-color: rgba(33, 41, 52, 0.85) !important;
    display: flex!important;

    &.hide {
      opacity: 0;
      transition: opacity 0.4s ease;
    }

    &.show {
      opacity: 1;
      transition: opacity 0.6s ease;
    }

    .rgpd-inner {
      background: #fff;
      border-radius: 2px;
      box-shadow: 0 1px 3px rgba(33,41,52,.75);
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: auto;
      max-width: 755px;
      min-width: 320px!important;
      position: relative;
      //padding: 2.5rem;
      //padding-bottom: 3rem;
      padding: 1.5rem;
      padding-bottom: 2rem;

      @media(max-width: 768px) {
        width: 94%;
        margin: auto;
        overflow: auto;
        max-width: 770px;
        min-width: auto;
        padding: 2.5rem 1.2rem 3rem;
        max-height: 89vh;
      }

      .main {
        text-align: center;

        p {
          color: black;
        }
      }

      .btn {
        color: white;
        background-color: #FB5058;
        font-size: 1rem;
        margin-top: 1rem;
        padding-left: 1.45rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-right: 1.45rem;
        margin-bottom: 1rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        font-weight: 600;
        border-radius: 0;
        letter-spacing: 0.5px;
        text-transform: uppercase;

        &.reject {
          background-color: #F1F1F1 !important;
          color: #b6b3b3;
        }

      }

      .accept-btns {
        @media(max-width: 768px){
            display: flex;
            flex-direction: column;
        }
      }

      .rgpd-settings {

        .accept-btns {
          //margin-top: 1rem;
        }

        .footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding-left: 1rem;
          padding-right: 1rem;

          .btn {
            margin-right: 0;
          }

        }

        .main {
          margin: 0;
          margin-left: 1rem;
          //margin-top: 1rem;
          margin-top: 0rem;
          margin-bottom: 1rem;
          margin-right: 1rem;
          padding-top: 0;
          //border: solid 1px #d9d9d9;
          padding-right: 0;
          padding-left: 0;
          padding-top: 0;

          .service .title {
            padding-left: 1rem;
            font-weight: bold;
            font-size: 0.8rem;
            text-align: left;
          }

          .service {
            padding-top: 1rem;
            padding-bottom: 1rem;
            //border-bottom: solid 1px #d9d9d9;
            display: flex;
            justify-content: space-between;
            padding-right: 1rem;

            .activate {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              span.value {
                font-size: 0.8rem;
                margin-top: 0.4rem;
              }
            }

            .desc {
              font-size: 0.8rem;
              padding-left: 1rem;
              margin-top: 1rem;
            }

          }

          .category-rgpd {
            border-bottom: solid 4px #efeaea;
            &:last-child {
              border:0;
            }
          }

          .category-rgpd > .title {
            text-transform: uppercase;
            padding-bottom: 0.75rem;
            padding-top: 1rem;
            border-bottom: solid 1px #d9d9d9;
            text-align: left;
            margin-top: 0;
            padding-left: 1rem;
            margin-bottom: 0;
            font-size: 1.3rem;
            color: black;
          }
        }

      }

      .header {
        padding: 1rem;
        text-align: center;
        font-size: 2rem;
        text-transform: uppercase;
        color: black;
      }

      .footer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .more, .back {
          margin-bottom: 0rem;
          color: #ACACAC;
          text-decoration: underline;
          font-size: 0.85rem;
          background: white;

          @media(max-width: 768px) {
            margin-top: 1rem;
          }
        }

      }

      .main {
        margin: 0;
        padding-left: 5rem;
        padding-right: 5rem;
        //padding-top: 2rem;
        padding-top: 1rem;

        @media(max-width: 768px) {
          margin: 0;
          padding-left: 2rem;
          padding-right: 2rem;
          padding-top: 1rem;
        }

        p {
          //font-size: 0.8rem;
          //line-height: 1.4rem;
          font-size: 0.7rem;
          line-height: 1.2rem;
        }

      }


    }

  }


}
