//@media (max-width: 768px){

@media (max-width: 1440px) {

  .article  {

    .author-block {
      max-width: 700px !important;
    }

    header  {
      .inner {
        .inner-block {
          .article-title {
            width: 85%;
          }
        }
      }
    }


  }

}

@media (min-width: 1500px) {

  .global-content {
    margin: inherit !important;
    //margin-left: 2rem !important;

  }

  .article .inner-main .right-sidebar {
    //right: 0;
    //position: absolute;
    position: relative;
    right: 0 !important;

    .socials-block {
      //right: 6rem !important;
    }

  }


}

@media (max-width: 1500px) {


  .article {

    .block-chapitre {
      visibility: hidden;
      width: 192px;
    }

    .inner-main {
      //margin-left: 3rem;
      //flex-basis: calc(100% - 6rem);

      .author-block {
        margin-left: 5rem;
        margin-right: 2.5rem;
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        margin-top: 3rem;
      }

      .right-sidebar {
        //flex-basis: 50px;
        width: 192px;

        .socials-block.fixed {
          position: fixed;
          top: 90px;
          right: 3rem !important;
        }

      }

      .description {
        p {
          //max-width: 900px;
          max-width: calc(100% - 50px);
        }
      }
    }

  }


}


@media (max-height: 850px) and (max-width: 1280px) {

  .article {

    header {
      .inner {

        .inner-block {
          height: 60% !important;
        }
      }
    }

  }

}

@media (max-width: 1025px) {

  .article {
    .inner-main {
      .right-sidebar {
        right: 1rem;

        .socials-block {
          &.fixed {
            right: 1rem;
          }
        }

      }
    }
  }


}

@media (min-height: 900px) and (max-width: 900px){

  .article {
    .inner-main {

      .global-content {
        margin: 0 auto;
        padding-left: 9%;
        padding-right: 9%;
      }

    }

  }



}

@media (max-width: 1025px) {

  .article .content {
    display: flex;
    flex: 1 1;
    flex-direction: column;
  }

  .article .inner-main .global-content {
    margin: 0 auto;
    padding-left: 9%;
    padding-right: 9%;
  }

  .article .inner-main .right-sidebar {
    flex: 0 1 100%;
    min-width: 100%;
    justify-content: center;
    padding-left: 0.4rem;
    box-sizing: border-box;
    position: relative;
    right: 0;
    margin-top: 3rem;

    .socials-block {
      position: relative !important;
      top: 0 !important;
      width: auto !important;
      right: 0 !important;

      .socials-inner {
        padding-top: 0;
        margin-bottom: 2rem;

        ul {
          justify-content: center;
          align-items: center;
          margin-bottom: 0;
          flex-direction: row;

          li {
            width: 33px;
            height: 33px;
            display: flex;
            align-items: center;
            margin-left: 0.6rem;
            margin-right: 0.6rem;
          }

        }

      }

    }

  }

}

@media (min-height: 900px) and (max-width: 1025px){

  .article {

    header {
      .inner {

        .inner-block {
          height: 72% !important;

          .article-title {
            width: 100%;
          }

        }

      }
    }

    .block-chapitre {
      visibility: hidden;
    }

    .content {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    .inner-main {
      flex-basis: calc(100%);
      margin-left: 0;

      .author-block .inner {
        transform: translateX(32px);
      }

      .global-content {
        margin: 0 auto;
      }

      .description {
        padding: 7%;
        padding-left: 9%;
        padding-right: 9%;
        //transform: translateX(2px);

        p {
          max-width: 100%;
          margin: 0;
        }

      }

      .right-sidebar {
        flex: 0 1 100%;
        min-width: 100%;
        justify-content: center;
        padding-left: 0.4rem;
        box-sizing: border-box;
        position: relative;
        right: 0;
        margin-top: 3rem;

        .socials-block {
          position: relative !important;
          top: 0 !important;
          width: auto !important;
          right: 0 !important;

          .socials-inner {
            padding-top: 0;
            margin-bottom: 2rem;

            ul {
              justify-content: center;
              align-items: center;
              margin-bottom: 0;
              flex-direction: row;

              li {
                width: 33px;
                height: 33px;
                display: flex;
                align-items: center;
                margin-left: 0.6rem;
                margin-right: 0.6rem;
              }

            }

          }

        }

      }

    }
  }

  .player-module  {

    .player-outer {
      left: 72px;
      width: calc(100% - 72px);

      .player-left {
        flex-basis: 30px;
      }
      .player-right {
        flex-basis: 30px;
      }

    }

    .player {
      opacity: 1 !important;
      transform: translateY(0%) !important;
    }

  }

}

@media (max-width: 425px){

  .player-module {
    .timeline-component {
      width: 92%;
      margin: 0 auto;
    }
    .category-timeline {
      &.mobile {
        width: 100% !important;
      }
    }
    .chapitre-point {
      visibility: hidden;
    }
  }


  .article {
    //transform: translateY(0);
    transform: translateY(-70px);

    .inner-main .right-sidebar {
      flex: 0 1 100%;
      min-width: 100%;
      justify-content: center;
      padding-left: 0;
      box-sizing: border-box;
      position: relative;
      right: 0;
      //margin-bottom: 2rem;
      margin-bottom: 8vh;
      //display: none;
    }

    .socials-block {
      .socials-inner {
        padding-top: 0 !important;

        ul {
          flex-direction: row !important;

          li {
            margin-left: 1rem;
            margin-right: 1rem;
            transform: scale(1.3);
          }

        }

      }
    }

    .inner-main .right-sidebar .socials-block.fixed {
      position: relative !important;
      top: 0 !important;
      width: auto !important;
      right: 0 !important;

      .socials-inner {
        padding-top: 0;
        margin-bottom: 2rem;

        ul {
          justify-content: center;
          align-items: center;
          margin-bottom: 0;
          flex-direction: row;

          li {
            width: 33px;
            height: 33px;
            display: flex;
            align-items: center;
            margin-left: 0.6rem;
            margin-right: 0.6rem;
          }

        }

      }

    }

    header .inner .inner-block .article-title {
      width: 100%;
    }

    .magazine-title {
      margin-left: 0;
      margin-top: 0;
      padding-left: 4% !important;

      img {
        width: 50vw;
      }

    }

    .inner .inner-block {
      height: 70% !important;
      padding-bottom: 7% !important;

      .chapitre {
        align-items: center;
      }

    }

    .frame {
      //height: 96vh !important;
    }

    .inner-main  {
      margin-left: 0;
      padding-left: 0;
      width: 100vw;
      flex-basis: 100%;

      .description {
        padding: 8%;
        margin-bottom: 0rem;

        p {
          max-width: calc(100%);
        }
      }

      .global-content {
        min-width: 100%;
        padding: 8%;
        width: 100%;
        padding-bottom: 0;
      }

      .author-block {
        margin-left: 0;

        .inner {
          transform: translateX(0);
          padding-left: 2rem;
          box-sizing: border-box;
        }

      }

    }

  }

  .player-outer {
    left: 0;
    width: 100%;
    margin-left: 0;
    justify-content: center;
    height: 80px;
    //bottom: 0;
    bottom: 13px;

    //&.full {
    //  transition: all 1s ease;
    //
    //  .player {
    //    padding-top: 2rem;
    //    transition: all 1s ease;
    //    height: 82px;
    //    padding-top: 2rem;
    //    transition: all 1s ease;
    //    width: 93% !important;
    //    flex: initial;
    //    min-width: 86% !important;
    //    margin: 0 auto !important;
    //  }
    //
    //}

    .player-left, .player-right {
      display: none;
    }

    .player {
      opacity: 1 !important;
      transform: translateY(0%) !important;
      padding-top: 1.2rem;
      height: 80px;
      overflow: hidden;
      padding-bottom: 1.2rem;
      width: 93% !important;
      flex: initial;
      min-width: 93% !important;
      margin: 0 auto !important;

      .title {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9rem;
        padding-bottom: 0rem;
      }

      .timeline-component .hoveringArticle {
        display: none;
      }

      .chapitre-title span.title {
        line-height: 1.2rem;
        font-size: 1.1rem;
      }

      .chapitre-title span.number {
        font-weight: 600;
        font-size: 1rem;
      }

      .btn {
        //width: 46px;
        background: transparent;
        border: 0 !important;

        &.next {
          transform: translateX(-6px);
        }
        &.prev {
          transform: translateX(6px);
        }

        i {
          //height: 16px;
          //width: 28px;
          //background-size: 78%;
          //filter: invert(21%);
        }

      }

    }

    .player-left {
      flex-basis: 0;
    }

  }

}

@media (max-width: 375px) {

  .article {
    header {

      .inner {
        .inner-block {

          .chapitre {
            align-items:center;
          }

          .article-title {
            font-size: 2.7rem;
            line-height: 3rem;
          }

        }
      }

    }

  }


}

@media (max-width: 320px) {

  .article {
    transform: translateY(-40px);
  }

  .player {

    .btn {
      transform: translateY(3px);
    }

    .btn.next {
      right: 0;
    }
    .btn.prev {
      left: 0;
    }
  }

}
