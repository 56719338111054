//@media (max-width: 768px){
@media (min-height: 900px) and (max-width: 1025px){

  .ademe {
    .menu {
      left: 72px;
      width: calc(100vw - 72px);

      .footer-menu ul li {
        margin-right: 1rem !important;
      }

    }

    .menu-inner {

      .articles-test {

        & > ul {
          & > li {
            flex: 0 1 calc(52% - 58px / 2);
            padding: 15px;
          }
        }

      }

    }
  }

}

@media (max-width: 425px){

  .ademe {
    .menu {
      width: calc(100vw);
      left: 0;
      overflow: scroll;
      height: 100vh !important;
      background:#2E6066;
      -webkit-overflow-scrolling: touch;

      .footer-menu {
        position: relative;
        padding-left: 0;
        padding-top: 2rem;
        padding-bottom: 2rem;
        height: auto !important;

        .inner-footer-menu {
          max-width: 100%;
          transform: translateX(0);
          flex-direction: column;

          .credits {
            margin-top: 1.5rem;
          }

        }

        ul {
          flex-direction: column;
          text-align: center;

          li {
            margin-right: 0 !important;
            font-size: 1.2rem;
            margin-bottom: 0.7rem;
          }

        }

      }

      .menu-inner {
        padding-top: 72px;
        padding-left: 6px;
        flex-direction: column;
        position: relative;
        height: auto;

        .categories {
          .chapitre-title {
            font-size: 1.4rem;
          }
        }

        .menu-numero {
          padding-left: 15px;
          padding-right: 15px;
          position: relative;

          i.icon-chevron_down {
            color: white;
            position: absolute;
            top: 50%;
            transform: translateY(calc(-50% - 2px));
            right: 1.5rem;
            font-size: 1.7rem;
          }

          .numeros-menu-outer {
            position: relative;
            background: rgba(66, 62, 222, 0.6901960784313725);
            padding: 12px;
            z-index: 1;
            width: 99%;
            margin-top: 1rem;
            bottom: 0;
            left: 0;
            /* -webkit-transform: translateY(100%); */
            -ms-transform: translateY(100%);
            transform: translateY(0%);
            padding-top: 0;
            padding-bottom: 0 !important;

            select {
              color: white;
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
              width: 100%;
              background: transparent;
              font-size: 1.2rem;
              padding-left: 0.7rem;
              border: 0 !important;
              outline: none;
            }

            .numeros-menu li {
              margin-bottom: 2.2em;
              margin-top: 1rem;

              a {
                font-size: 1.05rem;
                color: white;
              }

            }

          }

          .menu-numeros-btn {
            font-size: 0.9rem;
          }

        }

        .articles-test {
          margin-top: 1.4rem;
          overflow: inherit;

          > ul {
            padding-bottom: 3rem;
          }

        }

        .articles-test::-webkit-scrollbar {
          width: 5px;
        }

        .articles {
          height: auto;
          padding-bottom: 1rem;
          padding-top: 0;

          a {
            font-size: 1.2rem;
          }

        }

        .articles-test > ul {
          & > li {
            flex: 0 1 100%;
          }
        }

      }

    }
  }


}
