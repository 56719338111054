@import "../../mixins.scss";
@import "./slider.scss";
@import "./slider-media.scss";

.ademe {
  .svg-inline--fa {
    cursor: pointer;
  }

  //.global-content {
  //  h2 {
  //    font-size: 2.7rem;
  //    line-height: 3rem;
  //    margin-top: 1rem;
  //    text-transform: uppercase;
  //    font-weight: 400;
  //    margin-bottom: 1.1rem;
  //    color: black;
  //  }
  //
  //  h3 {
  //    font-size: 2rem;
  //    line-height: 2rem;
  //    margin-top: 1.3rem;
  //    margin-bottom: 1.3rem;
  //    text-transform: uppercase;
  //    font-weight: 400;
  //    color: black;
  //  }
  //}

  .couverture .magazine-title, .article .magazine-title {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    padding-left: 2em !important;
    padding-top: 1em !important;
    z-index: 2 !important;
    //transition: all 0.35s ease-out !important;

    a {
      color: white;
      text-decoration: none;
    }

    img {
      width: 247px;
    }

    h1 {
      font-size: 1.61em;

      a {
        position: relative;
        &:after {
          @include wait(#C6C5C7)
        }
      }

    }

  }

  .article .magazine-title {
    position: fixed !important;
    //margin-left: 120px;
    margin-left: 87px;
    //margin-top: 9px;
    //margin-top: 13px;
    margin-top: 7px;
    //z-index: 3 !important;
    //z-index: 101 !important;
    z-index: 1010000 !important;
  }

  @keyframes playerLoad {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .player-module {

    .player {
      opacity: 0;
      transform: translateY(50%);
      transition: all 0.1s ease-out;
    }

    &.active {

      .player {
        opacity: 1;
        transform: translateY(0%);
        transition: all 0.4s ease-out;
      }

    }

  }

  .player-outer {
    position: fixed;
    display: flex;
    left: 87px;
    width: calc(100% - 87px);
    //height: 60px;
    bottom: 17px;
    z-index: 10000;

    .player-left {
      //flex-basis: 248px;
      flex-basis: 100px;
    }

    .player-right {
      //flex-basis: 206px;
      flex-basis: 100px;
    }

  }

  .player {
    display: flex;
    //height: 60px;
    background: white;
    flex: 1 1;
    min-width: 400px;
    //max-width: 848px;
    justify-content: space-between;

    /* Rectangle 5: */
    //box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 20px 0 rgba(0,35,144,0.16);
    position: relative;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    box-sizing: border-box;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-radius: 8px;
    //overflow: hidden;
    //animation: playerLoad 0.6s ease-out forwards;

    .timeline {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .timeline-component {
      height: 15px;
      background: transparent;
      display: flex;
      position: relative;

      .hoveringArticle {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        padding: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 6px;
        background: black;
        color: white;
        z-index: 10;
        //height: 2px;
        //width: 2px;
        //background: red;
        //overflow: hidden;

        &:after {
          position: absolute;
          content: "";
          left: 50%;
          bottom: 0;
          width: 26px;
          height: 26px;
          transform: rotate(-45deg) translate(-50%, -10%);
          background: black;
          border-radius: 4px;z-index: -1;
        }

        span {
          font-size: 0.75rem;
        }

        span.number {
          font-weight: 800;
          font-size: 0.7rem;
          margin-right: 0.8em;
          line-height: 0.7rem;
          padding-left: 4px;
          padding-top: 4px;
          padding-bottom: 4px;
          padding-right: 4px;
          min-width: 19px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #FF5F7D;
          color: white;
        }
      }

    }

    .timeline-inner {
      height: 100%;
      width: 33%;
      border-radius: 6px;
      overflow: hidden;
      background: #2E6066;
      position: absolute;
      transition: all 0.6s ease-out;
    }

    .timeline-outer {
      height: 100%;
      border-radius: 6px;
      overflow: hidden;
      background: #DBDBDB;
      position: absolute;
      display: flex;
      width: 100%;
    }

    .category-timeline {
      position: relative;
    }

    .chapitre-point {
      width: 17px;
      height: 19px;
      transform: translateY(-50%) translateX(50%);
      top: 0;
      right: 0;
      position: absolute;
      cursor: pointer;
      z-index: 1;

      &:hover {
        cursor: pointer;

        div {
          //width: 14px;
          //height: 14px;
          border: solid 3px #FB5058 !important;
          box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.4);
        }

      }

      div {
        position: absolute;
        transform: translate(-50%, -10%);
        left: 50%;
        top: 50%;
        //width: 10px;
        //height: 10px;
        //border: solid 3px #DCDBDD;
        //width: 14px;
        //height: 14px;
        width: 15px;
        height: 15px;
        border: solid 3px #DCDBDD;
        background: white;
        border-radius: 100%;

        &.passed {
          border: solid 3px #2E6066;
        }

        &.current {
          //width: 14px;
          //height: 14px;
          //border: solid 3px #4E49FC;
        }

      }

    }

    .articles-in-category {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      height: 100%;
      z-index: 0;

      .article {
        transform: translateY(94%);
        width: 6px;
        height: 6px;
        background: white;
        display: flex;
        border-radius: 100%;
        z-index: 1;
        border: 0;
        background: rgba(0, 0, 0, 0.1);

        &.passed {
          border: 0;
          background: rgba(0, 0, 0, 0.4);
        }

        &.current {
          //border: solid 3px #4e4afb;
          //background: #4e4afb;
          border: 0;
          background: rgba(0, 0, 0, 0.4);
        }

      }

    }

    .chapitre-bullet {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      padding: 12px;
      padding-top: 12px;
      padding-bottom: 12px;
      border-radius: 6px;
      background: black;
      color: white;
      transform: translateY(calc(-100% - 1.3rem));
      z-index: 10;

      &:after {
        position: absolute;
        content: "";
        left: 50%;
        bottom: 0;
        width: 26px;
        height: 26px;
        transform: rotate(-45deg) translate(-50%, -10%);
        background: black;
        border-radius: 4px;z-index: -1;
      }

      span {
        font-size: 0.75rem;
      }

      span.number {
        font-weight: 800;
        font-size: 0.7rem;
        margin-right: 0.8em;
        line-height: 0.7rem;
        padding-left: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 4px;
        min-width: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FB5058;
        color: white;
      }

    }

    .chapitre-title {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      padding-bottom: 1rem;

      span.number {
        font-weight: 800;
        font-size: 0.7rem;
        margin-right: 1.2em;
        line-height: 0.7rem;
        padding-left: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 4px;
        min-width: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fb5058;
        color: white;
      }

      span.title {
        line-height: 0.7rem;
        font-weight: 400;
        letter-spacing: 0.3px;
        text-transform: uppercase;

        @media (max-width: 320px) {
          font-size: 1rem !important;
        }

      }

    }

    .btn {
      display: flex;
      justify-content: center;
      position: absolute;
      align-items: center;
      padding: 0rem;
      background: transparent;
      height: 22px;
      width: 22px;
      border-radius: 0;
      font-size: 1.3rem;
      border:0;
      transition: all 0.2s ease-out;

      &:hover {
        cursor: pointer;
        transition: all 0.5s ease-out;

        &.prev {

          span {
            display: block;
            opacity: 1;
            transform: translateX(0%);
          }

        }

        &.prev {

          span {
            display: block;
            opacity: 1;
            transform: translateX(0%);
          }

        }

      }

      a {
        width: 100%;
        height: 100%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;outline:none;

        &:hover {
          span {
            display: block;
          }
        }

        span {
          display: none;
          font-size: 0.8rem;
        }

      }

      &.prev {
        left: 1rem;

        i {
          color: #FB5058;
          //display: block;
          //background: url(../assets/arrow.svg);
          //transform: rotate(180deg);
          //height: 22px;
          //width: 22px;
          //background-size: 32px;
          //background-repeat: no-repeat;
          //background-size: contain;
          //background-position: center center;
        }

        span {
          display: block;
          opacity: 0;
          padding-right: 1rem;
          transform: translateX(-40%);
        }

      }

      &.next {
        right: 1rem;

        a {
          flex-direction: row-reverse;
        }

        i {
          color: #FB5058;
          //display: block;
          //background: url(../assets/arrow.svg);
          //height: 22px;
          //width: 60px;
          //background-size: 32px;
          //background-repeat: no-repeat;
          //background-size: contain;
          //background-position: center center;
        }

        span {
          display: block;
          opacity: 0;
          padding-left: 1rem;
          transform: translateX(40%);
        }

      }

    }

  }

  .article {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transform: translateY(-200px);

    &.scrolled {
      .magazine-title a {
        color: black;
      }
    }

    figure {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 1rem;

      img {
        //width: 100%;
        //height: auto;
        width: 100%;
        //height: 400px;
        object-fit: cover;
        background: #f1f1f1;
        height: auto;
      }

      figcaption {
        margin-top: 0.8rem;
        color: #666666;
        font-size: 0.94rem;
      }

    }

    header {
      width: 100%;
    }

    .sidebar {
      width: 300px;
    }

    .block-chapitre {
      width: 272px;

      .inner-chapitre {
        width: 272px;
        padding: 2rem;
        padding-top: 3rem;
        padding-right: 3rem;
        display: none;

        header {
          background: white;
        }

      }

      .inner-chapitre.fixed {
        position: fixed;
        top: 84px;
        width: 272px;
      }

      ul {
        li {
          display: flex;
          flex-direction: column;
          margin-bottom: 0.7rem;
          padding-bottom: 0.7rem;
          border-bottom: solid 1px rgba(0, 0, 0, 0.23137254901960785);
          position: relative;

          &:after {
            @include wait(#f3f0f0);
            height: 83% !important;
          }

          &:hover {
            cursor: pointer;
          }

          .author {
            font-size: 0.66rem;
            margin-top: 0.15rem;
          }

          &.current a {
            color: black;
          }

          a {
            color: #cacaca;
            font-weight: 600;
            font-size: 0.76rem;

            &:hover {
              font-weight: 600;
            }
          }

        }
      }

      h1 {
        color: black;
        //font-size: 22px;
        font-weight: 300;
        text-transform: uppercase;
        font-size: 2rem;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: solid 2px rgba(0, 0, 0, 0.27058823529411763);
        position: relative;

        &:after {
          @include wait(#eaeaea);
          height: 83% !important;
        }

      }

    }

    .article-title {
      font-size: 1rem;
      margin-top: 1rem;
      position: relative;

      &:after {
        @include wait(#bcbcbc)
      }

    }

    .inner-main {
      font-size: 1.1em;
      //max-width: 900px;
      //width: 70%;

      &:after {
        @include wait(#fff)
      }

      .content {
        display: flex;
        margin: 0 auto;
        justify-content: center;
      }

      .right-sidebar {
        display: flex;
        width: 270px;
        padding-left: 2.4rem;
        //position: absolute;
        //right: 3rem;

        .socials-block {
          width: 75px;

          &.fixed {
            position: fixed;
            top: 90px;
            right: 3rem;
          }

          .socials-inner {
            padding-top: 200px;

            ul {
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;

              li {
                width: 33px;
                height: 33px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 0.7rem;
                opacity: 0.4;

                &:hover {
                  opacity: 1;
                  cursor: pointer;
                }

                button {
                  padding: 0;
                  background: transparent;
                }

              }
            }

          }

        }

      }

      .author-block {
        background: #F1F1F1;
        padding: 5%;
        padding-left: 0;
        max-width: 900px;
        margin: 0 auto;
        margin-top: 3rem;

        @media (max-width: 425px) {
          margin-right: 0;
          margin-bottom: 6rem;
        }

        .inner {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          transform: translateX(-52px);

          .informations {

            .name {
              font-weight: 800;
              font-size: 1.3rem;
              margin-bottom: 0.3rem;
              display: flex;
            }

            .metier {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              i {
                display: flex;
                color: #4E49FC;
                margin-right: 0.4rem;
              }
            }

            .email {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              i {
                display: flex;
                color: #4E49FC;
                margin-right: 0.4rem;
              }
            }

            div {
              display: flex;
              flex-direction: column;
              font-size: 0.9rem;
            }

          }

        }

        .avatar {
          border-radius: 100%;
          width: 104px;
          height: 104px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1.3rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

        }

      }

      .global-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: 400px;
        max-width: 900px;
        //margin: 0 auto;
        //margin-bottom: 105px;
        min-height: 50vh;
        margin-bottom: 34px;

        a {
          text-decoration: underline !important;
          //&:hover {
          //  color: #f2d13e;
          //}
        }

        @media (max-width: 1440px) {
          max-width: 700px;
        }

        > div {
          position: relative;
          display: flex;
          flex-direction: column;

          &:after {
            @include wait(#f3f1f1);
          }
        }

      }

      .description {
        background: #F1F1F1;
        padding: 5%;
        position: relative;
        max-width: 900px;
        position: relative;
        font-weight: 700;
        padding-top: 3%;
        padding-bottom: 3%;
        margin: 0 auto;
        //margin-bottom: 3rem;
        margin-bottom: 1.5rem;

        a {
          color: white;
          &:hover {
            text-decoration: underline !important;
          }
        }

        &:after {
          content: "";
          background: #f1f1f1;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transform: translateX(100%);
        }

        div {
          position: relative;
          &:after {
            @include wait(#fff);
          }
        }

        p {
          width: 100%;
          //max-width: calc(100% - 272px);
          font-style: normal;
        }

      }

      p.style {
        border-left: solid 4px black;
        padding-left: 1.5rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
      }

      .citation {
        text-align: center;
        margin: 0 auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 80%;
      }

      .component-text {
        overflow: hidden;
        //font-size: 0.95rem;
        font-size: 1rem;
        letter-spacing: 0.15px;

        ul {
          padding-left: 1.5rem !important;

          li {
            list-style-type: disc !important;
            margin-bottom: 0.4rem !important;
          }

        }

        &.style {
          border-left: solid 5px black;
          padding-left: 0;
          margin-top: 0.75rem;
          margin-bottom: 0.75rem;

          ul {
            padding-left: 0 !important;
          }

        }

        p {
          overflow-wrap: break-word;
          margin-bottom: 0;
        }
      }

      .citation q {
        font-size: 1.9rem;
        line-height: 1.9rem;
        font-weight: 400;
        font-style: normal !important;
        color: #FB5058;
      }

      span.author {
        font-size: 1.05rem;
        line-height: 1.9rem;
        margin-top: .9rem;
        color: #000;
        text-decoration: none;
        font-style: italic;
      }

    }

    header {
      background: #b0b0b0;
      color: white;

      .inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        z-index: 2;

        .inner-block {
          //height: 53%;
          height: 59%;
          flex-basis: 100%;
          padding-left: 5%;
          padding-right: 5%;
          padding-bottom: 4%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .article-title {
            width: 70%;
            font-weight: 800;
            font-size: 3.09rem;
            line-height: 3.80rem;
          }

          @keyframes topHeader {
            0% {
              opacity: 0;
              transform: translateY(10%);
            }
            100% {
              opacity: 1;
              transform: translateY(0%);
            }
          }

          .top-header-article {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            //animation: topHeader 0.4s ease;
          }

          .bottom-header-article {
            font-weight: 300;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .legende {
              position: relative;
              &:after {
                @include wait(#c6c5c5);
              }
            }

            .infos {
              font-size: 1.04rem;
              line-height: 1.47rem;
              position: relative;
              &:after {
                @include wait(#c6c5c5);
              }
            }

          }

          .infos {
            .time {
              margin-right: 47px;
              margin: 0;
            }
          }

          .chapitre {
            font-weight: 300;
            text-transform: uppercase;
            display: flex;
            position: relative;

            &:after {
              @include wait(#cfcfcf)
            }

            .number {
              font-weight: 600;
              font-size: 1.2rem;
              margin-right: 0.5em;
              background: #FF5F7D;
              padding: 0.2rem;
              height: 30px;
              width: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

          }

        }

      }
    }
  }

  .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 36px;
    line-height: 1;
    opacity: .75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }

}

@media (min-width: 1500px){

  .ademe {
    .article {

      .block-chapitre {
        .inner-chapitre {
          display: block;
        }
      }
    }
  }


}

.ademe {
  @import "./components.scss";
  @import "./article-medias.scss";
}

@import "./content.scss";
