//@media (max-width: 768px){
@media (min-height: 900px) and (max-width: 1025px){

  .component {

    &.component-carrousel {
      width: 100%;
      div {
        width: 100%;
      }
    }

    &.component-chiffres {
      width: 100%;
      div {
        width: 100%;
      }
    }

  }

}
