@import "../../mixins.scss";

.ademe {

  .page-outer {
    padding-top: calc(87px + 3rem);
    padding-bottom: 4rem;

    @media (max-width: 425px) {
      padding-top: calc(87px + 1rem);
    }


    .page-inner {
      max-width: 700px;
      margin: 0 auto;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      flex-direction: column;

      .title-page {
        position: relative;
        display: flex;
        align-self: flex-start;
        margin-bottom: 1rem;

        &:after {
          @include wait(#e7e6e9)
        }
      }

      h1 {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 3rem;
      }

    }

  }


}
