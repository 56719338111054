@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?5ns9tx');
  src:  url('icomoon.eot?5ns9tx#iefix') format('embedded-opentype'),
  url('icomoon.ttf?5ns9tx') format('truetype'),
  url('icomoon.woff?5ns9tx') format('woff'),
  url('icomoon.svg?5ns9tx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_down:before {
  content: "\e900";
}
.icon-arrow_left:before {
  content: "\e901";
}
.icon-arrow_right:before {
  content: "\e902";
}
.icon-arrow_up:before {
  content: "\e903";
}
.icon-avatar_author:before {
  content: "\e904";
}
.icon-burger:before {
  content: "\e905";
}
.icon-check:before {
  content: "\e906";
}
.icon-chevron_down:before {
  content: "\e907";
}
.icon-chevron_left:before {
  content: "\e908";
}
.icon-chevron_right:before {
  content: "\e909";
}
.icon-chevron_up:before {
  content: "\e90a";
}
.icon-close:before {
  content: "\e90b";
}
.icon-facebook:before {
  content: "\e90c";
}
.icon-instagram:before {
  content: "\e90d";
}
.icon-mail_author:before {
  content: "\e90e";
}
.icon-status_done:before {
  content: "\e90f";
}
.icon-status_undone:before {
  content: "\e910";
}
.icon-time:before {
  content: "\e911";
}
.icon-twitter:before {
  content: "\e912";
}
