.ademe {
  .menu {
    //z-index: 10;
    z-index: 130000;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    border-left: 0 !important;
    //background-color: #4E49FC;
    background-color: transparent;
    overflow: hidden;
    //width: calc(100% - 120px);
    //margin-left: 120px;
    position: fixed;
    left: 86px;
    height: 100vh;
    width: calc(100vw - 86px);

    .footer-menu {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 11vh;
      width: 100%;
      background: #245258;
      padding-left: calc(38px + 15px);
      opacity: 0;
      justify-content: center;

      .inner-footer-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        //max-width: 1140px;
        max-width: calc(1140px - 40px);
        transform: translateX(-16px);

        .credits {
          font-size: 0.8rem;
          color: white;
        }

      }

      ul {
        display: flex;
        justify-content: flex-start;

        li {
          color: white;
          margin-right: 3rem !important;
          cursor: pointer;
          font-size: 0.8rem;
          font-weight: 600;

          *:hover, &:hover {
            text-decoration: underline;
          }

          a {
            color: white;
            &:hover{
              text-decoration: underline !important;
            }
          }
        }

      }

    }

  }

  .menu-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    //height: 104vh;
    width: 100%;
    background-color: transparent;
    opacity: 1;
    display: flex;
    padding-top: 137px;
    padding-left: 38px;
    //padding-bottom: 6rem;

    .chapitre {
      opacity: 0;
    }

    .categories {
      font-size: 1.4rem;
      color: #000;
      //margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      .chapitre-title {
        padding: 1.7rem;
        padding-top: 1.2rem;
        padding-bottom: 0.2rem;
        /* font-weight: bold; */
        background: white;
        font-size: 1.8rem;
        line-height: 1.9rem;
        color: #2E6066;
        text-transform: uppercase;
      }

      .chapitre-wallpaper {
        display: block;
        height: 133px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: #dadada;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .number {
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 9px;
        color: #fff;
        font-weight: bold;
        font-size: 1.4rem;
        margin-right: 26px;
        transform: translate(22%, -23%);
        z-index: 0;

        .inner {
          background-color: #FA5057;
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          border-top-right-radius: 3px;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 2px;
          width: 20px;
          height: 11px;
          background: #c53c41;
          clip-path: polygon(37% 0%, 100% 0%, 100% 100%, 0% 100%);
          z-index: -1;
        }

      }

    }

    .articles {
      padding: 1.7rem;
      margin-bottom: 1.5rem;
      height: 249px;
      padding-top: 0;
      overflow-y: scroll;
      padding-bottom: 10px;
      direction: ltr;
      background: white;

      &::-webkit-scrollbar {
        width: 7px;
      }

      //1C4347

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #1C4347;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #302d91;
      }

      li {
        cursor: pointer;
        padding-bottom: 0.7rem;
        padding-top: 0.6rem;
        border-bottom: solid 1px #DFDEEC;

        &:hover {
          text-decoration: underline;
        }

        &:last-child {
          border: 0;
        }

      }

      a {
        font-size: 0.92rem;
        font-weight: 600;
        //color: #969696;
        color: #A1A7BB;
      }

    }

    .articles-test {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      overflow-y: scroll;
      -webkit-overflow-scrolling:touch;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #1F4347;
      }

      &::-webkit-scrollbar-thumb {
        background: #fff;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #f1f1f1;
      }

      span.number {

      }

      > ul {
        width: 100%;
        max-width: 1140px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > li {
          flex: 0 33%;
          padding: 15px;

          div.inner {
            width: 100%;
            position: relative;

          }

        }

      }

    }

  }

  .background-menu {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    //background: #4d49fc;
    //background: #2e3dcc;
    background: #2E6066;
    opacity: 0;
  }

}

@import "./menu-media.scss";
